<template>
  <div class="data-grid-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>Exception Handling</p>
      </div>
      <div class="grid-toolbar-filters">
        <span class="dropdown-container">
          <MultiSelectorVue
            label="Exception Type"
            placeholder=""
            :selected="selectedExceptionTypes"
            :list="exceptionTypes"
            @onChange="onChangeExceptionTypeSelection"
          />
        </span>
        <span class="export-button">
          <button class="export-csv-button" @click="exportCSVAndDownload">
            <span class="export-icon">
              <span class="k-icon k-i-file-csv"></span>
            </span>
            Export CSV
          </button>
        </span>

        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :placeholder="'Search by Entity Name or SGA ID or Request ID or Ultimate Parent Request Id'"
            v-model="searchWord"
            :inputPrefix="'prefix'"
            @keyup.enter="filterGridData"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid"
      :style="'height: 100%;maxWidth: 100%'"
      class="grid-data-table"
      :data-items="gridData"
      :resizable="true"
      :sortable="true"
      :pageable="gridPageable"
      :sort="sort"
      :filterable="false"
      :take="take"
      :skip="skip"
      :total="totalRecords"
      :expand-field="expandField"
      :columns="columns"
      :row-render="cellFunction"
      @datastatechange="dataStateChange"
      @pagechange="pageChangeHandler"
    >
      <template v-slot:exceptionTypeTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div class="work-type-column-container">
            <div
              class="exception-types-container"
              v-if="props.dataItem && props.dataItem.exceptionType"
              :class="{ 'disabled-container': props.dataItem.isEditing }"
            >
              <div
                v-for="(exceptionType, index) in props.dataItem.exceptionType"
                :key="index"
                @click="goToDuplicates(props.dataItem, exceptionType)"
                :class="[
                  'work-type-content',
                  getExceptionTypeClass(exceptionType)
                ]"
              >
                {{ exceptionType }}
              </div>
            </div>
            <button
              v-if="props.dataItem.isEditing"
              class="save-button"
              @click="saveEditedLegalEntityName(props.dataItem)"
            >
              Save
            </button>
          </div>
        </td>
      </template>
      <template v-slot:viewEntityTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div class="relationship-button-container">
            {{ props.dataItem.sgaId }}
            <b-tooltip
              position="is-bottom"
              type="is-info"
              v-if="true"
              :label="'View'"
              :style="{ position: 'absolute' }"
            >
              <b-icon
                @click.native="navigateToViewEntity(props.dataItem.sgaId)"
                class="view-icon"
                icon="open-in-new"
                size="is-small"
                title="View"
              ></b-icon>
            </b-tooltip>
          </div>
        </td>
      </template>
      <template v-slot:editTemplate="{ props }">
        <td class="k-table-td edit-column-td" role="gridcell" colspan="1">
          <div class="edit-column-container">
            <template v-if="props.dataItem.isEditing">
              <k-input
                :style="{ width: '400px' }"
                :value="props.dataItem.editedLegalEntityName"
                @input="updateEditedLegalEntityName(props.dataItem, $event)"
              >
                <template v-slot:prefix>
                  <span class="k-input-icon k-icon k-i-search"></span>
                </template>
              </k-input>
            </template>

            <template v-else>
              {{ props.dataItem.legalEntityName }}
              <b-tooltip
                position="is-bottom"
                type="is-info"
                v-if="true"
                :label="'Edit'"
                :style="{ position: 'absolute' }"
              >
                <SvgIcon
                  v-if="showPencilIcon(props.dataItem)"
                  :icon="pencilIcon"
                  :size="'medium'"
                  class="edit-icon"
                  @click="startEditing(props.dataItem)"
                />
              </b-tooltip>
            </template>
          </div>
        </td>
      </template>
    </Grid>
    <ConfirmationDialog
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { mapActions, mapState } from 'vuex'
import { downloadCSV } from '../../../../util/util'
import Snackbar from '@/components/Snackbar'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import { pencilIcon, kpiStatusDenyIcon } from '@progress/kendo-svg-icons'
import { SvgIcon, KendoInput } from '@progress/kendo-vue-common'
import moment from 'moment'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'

const DEFAULT_EXCEPTION_TYPE = [
  { name: 'Duplicate', id: 'Duplicate' },
  { name: 'Invalid', id: 'Invalid' }
]

export default {
  name: 'ExceptionHandlingTableGrid',
  components: {
    Grid,
    SvgIcon,
    'k-input': Input,
    KendoInput,
    ConfirmationDialog,
    MultiSelectorVue
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      pencilIcon,
      kpiStatusDenyIcon,
      selectedExceptionTypes: DEFAULT_EXCEPTION_TYPE,
      exceptionTypes: DEFAULT_EXCEPTION_TYPE,
      searchQuery: '',
      expandField: 'expanded',

      gridPageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      skip: 0,
      take: 10,
      total: this.totalRecords,
      sort: [],
      filter: null,
      customFilter: null,
      exceptionHandlingDataItem: [],
      expandedItems: [],
      editingDataItem: null,
      loading: false,
      dataItems: [],
      searchWord: '',
      // confirmation popup
      dialog: {
        visible: false,
        title: 'Confirm',
        text: '',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    }
  },
  computed: {
    ...mapState('exceptionHandling', [
      'exceptionHandlingList',
      'limit',
      'offset',
      'totalRecords'
    ])
  },
  watch: {
    exceptionHandlingList: {
      handler() {
        this.exceptionHandlingDataItem = this.exceptionHandlingList
        this.updateGridData()
      },
      immediate: true
    },
    limit(newVal) {
      this.take = newVal
    },
    offset(newVal) {
      this.skip = newVal
    },
    totalRecords(newVal) {
      this.total = newVal
    }
  },
  async mounted() {
    this.applyRowColors()
    await this.resetPaginationData()
  },
  methods: {
    // GRID UTILITIES
    ...mapActions('dmp', ['updateEntitiesStatus']),
    ...mapActions('exceptionHandling', [
      'getExceptionHandlingDuplicates',
      'updateInvalidEntity',
      'setLimitAndOffset',
      'getExceptionHandling',
      'updateSearchQuery',
      'resetPaginationData',
      'updateExceptionTypeFilter',
      'updateExceptionalHandlingData',
      'updatePaginationData'
    ]),
    applyRowColors() {
      const rows = document.querySelectorAll('.k-grid-table tr')
      rows.forEach((row, index) => {
        const exceptionType = this.gridData[index]?.exceptionType[0]
        if (exceptionType) {
          row.classList.add(this.getExceptionTypeClass(exceptionType))
          row.dataset.exceptionType = exceptionType
        }
      })
    },

    startEditing(dataItem) {
      this.editingDataItem = dataItem
      this.$set(dataItem, 'isEditing', true)
      // Create a copy of the original value for potential rollback
      this.$set(dataItem, 'editedLegalEntityName', dataItem.legalEntityName)
    },

    updateEditedLegalEntityName(dataItem, event) {
      this.$set(dataItem, 'editedLegalEntityName', event.target.value)
    },

    async saveEditedLegalEntityName(dataItem) {
      this.dialog.visible = true
      this.dialog.text = `
      <div style="margin-bottom: 10px;"><b>Are you sure you want to mark the below entity as valid?</b></div>
      <div style="text-align: left;">
    <div><b>SGA ID: </b> ${dataItem.sgaId}</div>
    <div> <b>Party ID: </b>${dataItem.requestId}</div>
    <div> <b>Legal Entity Name: </b>${dataItem.editedLegalEntityName}</div>
</div>
  `

      this.dialog.triggerFunction = async () => {
        this.$set(dataItem, 'legalEntityName', dataItem.editedLegalEntityName)
        this.$set(dataItem, 'isEditing', false)

        try {
          this.loading = true
          const updateInvalidResult = await this.updateInvalidEntity({
            updateInvalidPayload: {
              isInvalid: false,
              legalEntityName: dataItem.editedLegalEntityName
            },
            sgaId: dataItem.sgaId
          })

          if (updateInvalidResult) {
            this.loading = true
            this.getExceptionHandling()
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
        } finally {
          this.loading = false
        }

        this.editingDataItem = null
        this.dialog.visible = false
      }
    },

    closeDialog() {
      if (this.editingDataItem) {
        this.$set(this.editingDataItem, 'isEditing', false)
        this.editingDataItem = null
      }
      this.dialog.visible = false
    },

    showPencilIcon(dataItem) {
      return (
        dataItem.exceptionType && dataItem.exceptionType.includes('Invalid')
      )
    },

    async navigateToViewEntity(sgaId) {
      // View Entity Redirection
      await this.updateEntitiesStatus({
        entityStatus: false,
        entityDetails: { sgaId }
      })
      this.$router.push({
        name: 'viewEntity'
      })
    },

    processGridData(data, skipPagination = false) {
      return process(data, {
        take: skipPagination ? data.length : this.take,
        skip: skipPagination ? 0 : 0, // hardcoding skip to 0 as data is fetched through server side pagination
        sort: this.sort,
        group: this.group,
        filter: this.filter
      })
    },
    pageChangeHandler: async function (event) {
      this.skip = event.page.skip // offset
      this.take = event.page.take // limit
      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      this.loading = true
      await this.getExceptionHandling()
      this.loading = false
    },
    async filterGridData(event) {
      this.loading = true
      this.updateSearchQuery(this.searchWord)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getExceptionHandling()
      this.loading = false
      this.customFilter = this.constructFilter(this.searchQuery.toLowerCase(), [
        'sgaId',
        'requestId',
        'legalEntityName',
        'ultimateParentRequestId'
      ])
      this.skip = 0
      this.updateGridData()
      this.loading = false
    },
    async goToDuplicates(dataItem, exceptionType) {
      const { sgaId, legalEntityName, requestId } = dataItem
      if (exceptionType === 'Duplicate') {
        this.loading = true
        await this.updateEntitiesStatus({
          entityStatus: false,
          entityDetails: { sgaId, exceptionType }
        })
        await this.getExceptionHandlingDuplicates(sgaId)
        this.loading = false
        this.$router.push({
          name: 'exception-handling-duplicates'
        })
      } else {
        this.dialog.visible = true
        this.dialog.text = `
        <div style="margin-bottom: 10px;">
  <b>Are you sure you want to mark the below entity as invalid?</b>
</div>
<div style="text-align: left;">
  <div><b>SGA ID: </b>${sgaId} </div>
  <div><b>Party ID: </b>${requestId} </div>
  <div><b>Legal Entity Name: </b>${legalEntityName}</div>
</div>`
        this.dialog.triggerFunction = async () => {
          this.$set(dataItem, 'legalEntityName', legalEntityName)
          try {
            this.loading = true
            const updateInvalidResult = await this.updateInvalidEntity({
              updateInvalidPayload: {
                isInvalid: true,
                legalEntityName: legalEntityName
              },
              sgaId: sgaId
            })

            if (updateInvalidResult) {
              this.loading = true
              this.getExceptionHandling()
              this.loading = false
            }
          } catch (error) {
            // Handle Error Part
          } finally {
            this.loading = false
          }

          this.editingDataItem = null
          this.dialog.visible = false
        }
      }
    },
    updateGridData(skipPagination = false) {
      let filteredData = this.exceptionHandlingDataItem

      if (this.customFilter) {
        filteredData = this.exceptionHandlingDataItem.filter((item) => {
          return this.customFilter.filters.some((filter) => {
            if (item[filter.field]) {
              return item[filter.field]
                .toString()
                .toLowerCase()
                .includes(filter.value)
            }
            return null
          })
        })
      }

      const processedData = this.processGridData(filteredData, skipPagination)
      this.gridData = processedData.data
      this.total = processedData.total
    },

    exportCSVAndDownload() {
      const filteredData = this.customFilter
        ? this.exceptionHandlingDataItem.filter((item) =>
            this.customFilter.filters.some((filter) =>
              (item[filter.field] || '')
                .toString()
                .toLowerCase()
                .includes(filter.value)
            )
          )
        : this.exceptionHandlingDataItem

      const processedData = this.processGridData(filteredData, true)
      const dataForExport = processedData.data

      if (!dataForExport.length) {
        Snackbar({ message: 'No Data to export.', type: 'is-danger' })
        return
      }

      const columnHeaders = []
      const columnFields = []

      this.columns.forEach((column) => {
        if (column.title !== 'View') {
          columnHeaders.push(column.title || column.field)
          columnFields.push(column.field)
        }
      })

      const headers = columnHeaders.join(';')

      const csvData = dataForExport.map((item) =>
        columnFields
          .map((field) => {
            if (![null, undefined].includes(item[field])) {
              if (typeof item[field] === 'number') {
                return item[field].toLocaleString('en-US', {
                  maximumFractionDigits: 0
                })
              } else {
                return item[field].toString()
              }
            }
            return ''
          })
          .join(';')
      )

      const fileName = `exception_handling-data_${moment().format(
        'DD-MM-YYYY_HH:mm'
      )}.csv`
      const csvContent = `sep=;\n${headers}\n${csvData.join('\n')}`

      downloadCSV(csvContent, fileName)
    },

    // FILTERING
    constructFilter(inputValue, filterFields) {
      const filters = []
      const isValueArray = Array.isArray(inputValue)
      const isFieldArray = Array.isArray(filterFields)

      const addFilters = (field, value) => {
        filters.push({
          field: field,
          operator: 'contains',
          value: value,
          ignoreCase: true
        })
      }

      if (isValueArray) {
        inputValue.forEach((value) => {
          if (isFieldArray) {
            filterFields.forEach((field) => addFilters(field, value))
          } else {
            addFilters(filterFields, value)
          }
        })
      } else {
        if (isFieldArray) {
          filterFields.forEach((field) => addFilters(field, inputValue))
        } else {
          addFilters(filterFields, inputValue)
        }
      }

      return {
        logic: 'or',
        filters: filters
      }
    },
    dataStateChange(event) {
      this.filter = event.data.filter || this.filter
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort

      if (
        this.selectedExceptionTypes &&
        this.selectedExceptionTypes.length === 0
      ) {
        this.gridData = []
      } else {
        this.updateGridData()
      }
    },
    async onChangeExceptionTypeSelection(value) {
      this.selectedExceptionTypes = value
      const exceptionTypeArr = this.selectedExceptionTypes.map(
        (item) => item.name
      )
      if (exceptionTypeArr && exceptionTypeArr.length > 0) {
        this.loading = true
        await this.updateSearchQuery(this.searchWord)
        await this.updateExceptionTypeFilter(exceptionTypeArr)
        await this.setLimitAndOffset({ limit: this.take, offset: 0 })
        await this.getExceptionHandling()
        this.loading = false
      } else {
        const paginationData = {
          limit: 10,
          offset: 0,
          totalRows: 0
        }
        this.updatePaginationData(paginationData)
        this.updateExceptionalHandlingData([])
      }
    },

    // RENDERING UTILITIES
    getExceptionTypeClass(exceptionType) {
      switch (exceptionType) {
        case 'Duplicate':
          return 'work-type-corporate-action'
        case 'Invalid':
          return 'work-type-data-enrichment'
        default:
          return ''
      }
    },
    getRowClass(dataItem) {
      if (
        dataItem.exceptionType &&
        dataItem.exceptionType.includes('Duplicate') &&
        dataItem.exceptionType.length === 1
      ) {
        return 'highlight-row'
      }
      return ''
    },
    cellFunction(h, trElement, defaultSlots, props) {
      const rowClass = this.getRowClass(props.dataItem)
      const existingClass = trElement.data && trElement.data.class
      const combinedClass = `${existingClass} ${rowClass}`
      const trProps = {
        class: combinedClass
      }
      return h('tr', trProps, defaultSlots)
    }
  }
}
</script>

<style lang="scss" scoped>
.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  background: rgba(0, 0, 0, 0.1);
  z-index: 9;

  .inava-loader {
    position: relative;
    display: flex;
    margin: auto;
    border-radius: 50%;
    border: none;
    z-index: 900;
    width: 70px;
    height: 70px;
    background-image: '@/assets/Inava-logo-GIF.gif';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.data-grid-container {
  margin-left: 27px;
  margin-right: 27px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ::v-deep .k-grid-header {
    padding: 0px;
  }
  ::v-deep .k-grid-header-table {
    width: 100% !important ;
  }
  ::v-deep .k-grid-table {
    width: 100% !important ;
  }

  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }

    .grid-toolbar-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .export-button {
        margin-right: 10px;
      }

      .export-csv-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        color: #363636;
        border: solid 1px grey;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        height: 36px;
        transition: background-color 0.3s ease;
        font-size: 14px;
        min-width: 150px;
        font-weight: bold;
      }

      .export-csv-button:hover {
        background-color: #5c5a5a;
        color: #ffffff;
      }

      .export-icon {
        margin-right: 5px;
      }

      .dropdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.6rem;
        width: 100%;

        ::v-deep .data-source-container {
          display: flex;
          align-items: center;

          .top-label {
            font-size: 14px;
            line-height: 20px;
            font-family: Quicksand;
            font-weight: 600;
            font-style: normal;
            color: #7d7d7d;
            margin-right: 0.6rem;
            margin-top: 0.3rem;
          }

          .dropdown-wrap {
            // height: 40px;
            .data-source-header {
              background-color: #ffffff;
              border: 1px solid #cccccc;

              // color: black;
              .button-container {
                .ellipsis {
                  color: black;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;
                  font-weight: 600;
                  font-style: normal;
                }

                svg path {
                  stroke-width: 1;
                  stroke: black;
                }
              }
            }

            .data-source-abs {
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #000000;

              .custom-checkbox {
                p {
                  color: #000000;
                }
              }

              .custom-checkbox:hover {
                background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      ::v-deep .k-grid-search {
        height: 36px;
        width: 100%;

        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }

  .work-type-column-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .exception-types-container {
    display: flex;
    gap: 0.5rem;
  }

  .exception-types-container.disabled-container {
    opacity: 0.5;

    pointer-events: none;
  }

  .work-type-content {
    border: 1px solid #444444;
    border-radius: 4px;
    opacity: 1;
    width: 140px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    font-family: Quicksand;
    letter-spacing: 0.16px;
    color: #444444;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .work-type-corporate-action {
    color: #e10d0d;
    border: 1px solid #e10d0d;
    transition: background-color 0.3s, color 0.3s;
  }

  .work-type-data-enrichment {
    color: #00218a;
    border: 1px solid #00218a;
    transition: background-color 0.3s, color 0.3s;
  }

  .save-button {
    border: 1px solid #444444;
    border-radius: 4px;
    width: 65px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    font-family: Quicksand;
    letter-spacing: 0.16px;
    background-color: #00218a;
    color: #ffffff;
  }

  .view-icon {
    cursor: pointer;
    color: #535eeb;
  }

  .edit-icon {
    cursor: pointer;
    margin-left: 5px;
  }
}

::v-deep .k-alt {
  background-color: none;
}

::v-deep .highlight-row {
  background-color: rgb(245, 245, 245);
}

::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
}

::v-deep .k-table-td {
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #000000;
}

::v-deep .k-table-thead {
  background: #dfdfdf 0% 0% no-repeat padding-box;
}

::v-deep .k-grid-table tr {
  transition: background-color 0.3s ease;
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 31px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 16px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 16px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 16px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 14px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 16px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 15px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

@media (min-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 36px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 18px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 18px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 18px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 16px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 16px;
        line-height: 20px;
        font-family: Quicksand;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 18px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}
</style>
